<template>
  <div class="app-container summary-page">
    <eHeader
            :query="query"
            :tableStatus="tableStatus"
            @toQuery="toQuery"
            @clear="clear"
            @export="exportHandle"
            ref="header"
            :show-export="showExport"
    />
    <!--表格渲染-->
    <blockquote class="my-blockquote statistics-box">
      <div class="statistics-item">
        总额:${{statistics.amountSum}}
        <el-divider
          direction="vertical"
          class="statistics-item-divider"
        ></el-divider>
      </div>
      <div class="statistics-item">
        内转: ${{statistics.turn}}
        <el-divider
          direction="vertical"
          class="statistics-item-divider"
        ></el-divider>
      </div>
      <div class="statistics-item">
        出佣: ${{statistics.commission}}
      </div>
    </blockquote>
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
              v-if="selectCheck()"
              type="selection"
              key="1"
      >
      </el-table-column>
      <el-table-column prop="id" label="ID"/>
      <el-table-column label="客户信息">
        <template slot-scope="scope">
          <div>{{scope.row.userName}}</div>
          <div>{{scope.row.merchantId}}</div>
          <div>{{scope.row.email}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
          <div v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></div>
          <div v-if="scope.row.managerId!=null">{{scope.row.managerId}}</div>
          <div v-if="scope.row.managerName==null&&scope.row.managerId==null">无</div>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="出佣类型">
        <template slot-scope="scope">
          <span>{{ parseCommiType(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="出佣金额 ($)"/>
      <el-table-column prop="chkStatus" label="付款状态">
        <template slot-scope="scope">
          <span>{{ getPaytatus(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="chkStatus" label="审核状态">
        <template slot-scope="scope">
          <span>{{ parseCommiStatus(scope.row.chkStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ getAuditTime(scope.row.chkTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template slot-scope="scope">
          <!--添加查看的tab的权限-->
          <el-button
              v-if="checkPermission(['ADMIN','COMMI_ALL','COMMI_VIEW','COMMISSION_INFO_ALL','COMMISSION_APPLY_INFO','COMMISSION_CUST_INFO','COMMISSION_AUDIT_INFO'])"
              size="mini"
              type="success"
              style="margin-right: 3px;"
              @click="to(scope.row,false)"
          >查看</el-button>
          <!--          <check
              class="my-button"

              :data="scope.row"
              :sup_this="sup_this"
              :isCheck="true"
              :tableStatus="tableStatus"
          />-->
          <el-button
              v-if="checkPermission(['ADMIN','COMMI_ALL','COMMI_CHECK'])&&tableStatus!='-1'&&tableStatus!='6'&&tableStatus!='7'"
              size="mini"
              type="danger"
              @click="to(scope.row,true)"
          >审核</el-button>
          <el-button
              v-if="outCommissionBtnShow(scope.row)"
              size="mini"
              @click="withdrawHandle(scope.row)"
              type="primary"
          >出金</el-button>
          <editcommi class="my-button" v-if="checkPermission(['ADMIN','COMMI_ALL','COMMI_EDIT'])&&tableStatus=='-1'" :data="scope.row" :sup_this="sup_this"/>
          <el-button
              type="primary"
              size="mini"
              class="my-button"
              v-if="checkPermission(['ADMIN','COMMI_ALL','COMMI_STATUS_UPDATE']) && tableStatus=='-1'" @click="doStatus(scope.row)"
          >
            状态调整
          </el-button>
          <el-button
              type="danger"
              size="mini"
              class="my-button"
              v-if="getDayStatusAdjustBtnShow(scope.row)"
              @click="adjustPayStatusHandle(scope.row)"
          >
            支付状态调整
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>

    <el-dialog :append-to-body="true" :visible.sync="statusLoading" title="状态调整" width="500px">
     <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="申请编号">
        <el-input v-model="form.id" style="width: 250px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="修改前状态">
        <el-input v-model="form.befChkStatusDesc" style="width: 250px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="修改后状态">
         <el-select v-model="form.updateChkStatus" placeholder="请选择状态">
          <el-option
            v-for="item in commiStatusOptions"
            :key="item.key"
            :label="item.display_name"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" :rows="2" style="width: 250px;"/>
      </el-form-item>
    </el-form>
    <div lot="footer" class="dialog-footer" style="text-align: right;">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">调整</el-button>
    </div>
  </el-dialog>
    <audit-submit-bar
            :audit-select-data="multipleSelection"
            @submit="submit"
    ></audit-submit-bar>
    <batch-review-dialog
            ref="batchreview"
            :dialog-show="batchDialogShow"
            @close="closeHandle"
            @confirm="cofrimHandle"
    ></batch-review-dialog>
    <detail
        ref="detail"
        :sup_this="sup_this"
        :isCheck="isAudit"
        :data="currentRow"
        :tableStatus="tableStatus"
        :isWithdrawOperate="isWithdrawOperate"
    />
    <pay-status-adjust-dialog
        :dialog-show="payStatusAdjustDialogShow"
        :row-data="currentRow"
        @close="closePayStatusAdjustDialog"
        @edit-success="payStatusAdjustSuccess"
    ></pay-status-adjust-dialog>
  </div>
</template>

<script>
import payStatusAdjustDialog from '@/components/finance/merchantcommission/payStatusAdjustDialog'
  import { initData } from '@/api/data'
  import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
  import { batchCommApplyAudit } from '@/api/ib/ibMerchantCommission'
  import batchAudit from '@/mixins/batchAudit'
  import batchReviewDialog from '@/components/public/batchReviewDialog'
  import auditSubmitBar from '@/components/public/auditSubmitBar'
import { mapGetters } from 'vuex'
import checkPermission from '@/utils/permission'
import initDataInit from '@/mixins/initData'
import { modifyStatus } from '@/api/ib/ibMerchantCommission';
import { parseTime } from '@/utils/index';
import editcommi from './edit';
import detail from './detail';
import eHeader from './header';
import { parseCommiStatus,parseCommiType } from '@/views/pt/ib/utils/index';
export default {
  components: {
    eHeader,
    editcommi,
    auditSubmitBar,
    batchReviewDialog,
    detail,
    payStatusAdjustDialog,
  },
  mixins: [initDataInit,batchAudit],
  props:{
     tableStatus: {
      type: Number,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    },
    showExport:{
      type: Boolean,
      default: true
    }
  },
  watch:{
    refresh( newData,oldData){
      this.beforeInit()
      this.init()
    }
  },
  computed: {
    ...mapGetters([
      'commiStatusOptions'
    ])
  },
  data() {
    return {
      loading: false,
      statusLoading: false,
      sup_this: this,
      selectedRow:null,
      form: {
        id: '',
        chkStatus: 1,
        updateChkStatus:1,
        befChkStatusDesc:'',
        remark:'',
      },
      statistics:{
        amountSum: ''
      },
      currentRow:{},
      isAudit: false,
      isWithdrawOperate: false,
      payStatusAdjustDialogShow: false,
    }
  },
  created() {
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    parseTime,
    checkPermission,
    parseCommiStatus,
    parseCommiType,
    beforeInit() {
      this.url = '/crm/tMerchantCommission/queryCommByJoinMer'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const merchantId = query.merchantId
      const type = query.type
      const chkStatus=query.chkStatus
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)
      const managerInfo = query.managerInfo
      if (this.tableStatus!='-1') {
        this.params['chkStatus'] = this.tableStatus
      }else if (chkStatus!=null&&chkStatus!='') {
        this.params['chkStatus'] = chkStatus
      }
      if (managerInfo!=null && managerInfo!='') { this.params['managerInfo'] = managerInfo }
      if (merchantId!=null && merchantId!='') { this.params['merchantId'] = merchantId }
      if (type!== null && type!== '') { this.params['types'] = type }
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      if(this.query.custInfo){
        this.params.custInfo = this.query.custInfo;
      }
      return true;
    },
    to(row,isAudit,callback) {
      callback && callback();
      this.isAudit = isAudit;
      this.currentRow = row;
      const _this = this.$refs.detail;
      var url = '/crm/tCommissionEvent';
      const params = { commissionId: row.id};
      if(this.tableStatus == 5 || callback){
        _this.getPayFeeConfig();
      }
      initData(url,params).then(res=>{
        _this.events = res.content;
        _this.dialog = true;
      }).catch(err => {
        console.log(err.response.data.message)
      });
    },
    getPaytatus(row){
      switch (row.payStatus){
        case 1:
          return '待支付';
        case 2:
          return '支付中';
        case 3:
          return '支付成功';
        case 4:
          return '支付失败';
      }
    },
    doStatus(selectedRow){
      this.statusLoading=true
      this.selectedRow=selectedRow
      this.form={
        id: selectedRow.id,
        chkStatus: selectedRow.chkStatus,
        updateChkStatus:1,
        remark:''
      }
      for (var i = this.commiStatusOptions.length - 1; i >= 0; i--) {
        if(this.commiStatusOptions[i].key==selectedRow.chkStatus){
          this.form.befChkStatusDesc=this.commiStatusOptions[i].display_name
        }
      }
    },
    outCommissionBtnShow(row){
      return checkPermission(['ADMIN','COMMI_ALL','COMMI_CHECK']) && (this.tableStatus == 6 && row.payStatus == 4);
    },
    cancel(){
      this.statusLoading=false
      this.form = {
        id: '',
        chkStatus: 1,
        updateChkStatus:1,
        befChkStatusDesc:'',
        remark:''
      }
      this.selectedRow=null
    },
    doSubmit(){
      this.loading = true;
      const params = { id: this.selectedRow.id, chkStatus: this.form.updateChkStatus };
      if(this.form.remark!=null&&this.form.remark!=''){
        params['auditContent']=this.form.remark
      }
      //接口调用
      modifyStatus(params).then(res => {
        this.$notify({
          title: '调整成功',
          type: 'success',
          duration: 2500
        })
        this.cancel()
        this.loading = false
        this.refresh=!this.refresh
      }).catch(err => {
        this.loading = false
      })
    },
    getAuditTime(chkTime){
      if(chkTime){
        return parseTime(chkTime);
      }else {
        return '-'
      }
    },
    clear(){
      this.query = {};
      this.init();
    },
    cofrimHandle(paramsObj){
      let params = {
        ids: this.getIds(),
        auditBy: this.getAuditBy(),
        ...paramsObj
      };
      batchCommApplyAudit(params).then((res) => {
        this.$notify({
          title: '成功',
          type: "success",
          duration: 2500
        });
        this.batchDialogShow = false;
        this.init();
      })
    },
    exportHandle(){
      const sort = 'id,desc'
      let params = {
        sort: sort,
        export: 1,
      }
      const query = this.query
      const merchantId = query.merchantId
      const type = query.type
      const chkStatus=query.chkStatus
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)
      const managerInfo = query.managerInfo
      if (this.tableStatus!='-1') {
        params['chkStatus'] = this.tableStatus
      }else if (chkStatus!=null&&chkStatus!='') {
        params['chkStatus'] = chkStatus
      }
      if (managerInfo!=null && managerInfo!='') { params['managerInfo'] = managerInfo }
      if (merchantId!=null && merchantId!='') { params['merchantId'] = merchantId }
      if (type!== null && type!== '') { params['types'] = type }
      if (startTime!=null && startTime!='') { params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { params['endTime'] = endTime }
      if(this.query.startAuditTime){
        params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      if(this.query.custInfo){
        params.custInfo = this.query.custInfo;
      }
      this.$refs['header'].loading = true;
      initData(this.url, params).then(res => {
        for (let i = 0; i < res.content.length; i++) {
          res.content[i].commiType = parseCommiType(res.content[i].type);
          res.content[i].rmb = (res.content[i].amount * res.content[i].nominalExRate).toFixed(2);
          if(res.content[i].snapshotList){
            for (let j = 0; j < res.content[i].snapshotList.length; j++) {
              if(res.content[i].snapshotList[j].payTypeId == 8){
                res.content[i].usdtAddress = res.content[i].snapshotList[j].custPayDetail.address;
              }
              if(res.content[i].snapshotList[j].payTypeId == 9){
                res.content[i].otcAddress = res.content[i].snapshotList[j].custPayDetail.address;
              }
            }
          }
        }
        const tHeader =['客户名称','出佣类型','出佣金额($)','汇率','人民币金额(￥)','银行名称','银行账号','USDT地址','OTC365地址'];
        const filterVal =['userName','commiType','amount','nominalExRate','rmb','bankName','bankAccount','usdtAddress','otcAddress'];

        const exportData = formatJson(filterVal,res.content);
        let fileName='出佣报表';
        export_json_to_excel(tHeader,exportData,fileName);
        this.$refs['header'].loading =false;
      }).catch(err => {
        this.$refs['header'].loading =false
      })
    },
    withdrawHandle(row){
      this.to(row,true,() => {
        this.isWithdrawOperate = true;
      })
    },
    getDayStatusAdjustBtnShow(row){
      return checkPermission(['ADMIN','MODIFY_PAY_ALL','MODIFY_PAY_EDIT']) && this.tableStatus == '-1'
    },
    adjustPayStatusHandle(row){
      this.currentRow = row;
      this.payStatusAdjustDialogShow = true;
    },
    closePayStatusAdjustDialog(){
      this.currentRow = {};
      this.payStatusAdjustDialogShow = false;

    },
    payStatusAdjustSuccess(){
      this.currentRow = {};
      this.payStatusAdjustDialogShow = false;
      this.init();
    }
  },
}
</script>

